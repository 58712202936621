import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Notify } from 'notiflix';
import { AppointmentDto } from '../../dto/appointment_dto';
import { useAppDispatch } from '../../redux/store';
import { addAppointment } from '../../redux/slices/appointments';
import { useState } from 'react';

function Appointment() {
    const [loading, setLoading] = useState(false);

    const appointmentSchema = yup.object().shape({
        fullName: yup.string().required('Full name is required'),
        email: yup.string().email('Invalid email address').required('Email is required'),
        phone: yup.string().required('Phone is required'),
        date: yup.string().required('Date is required'),
        time: yup.string().required('Time is required'),
        style: yup.string().required('Style is required'),
        customStyle: yup.string(),
        createdAt: yup.number(),
    });

    const { register, handleSubmit, watch, reset } = useForm({
        resolver: yupResolver(appointmentSchema),
    });

    const style = watch("style");

    const dispatch = useAppDispatch();

    const onSubmit = async (appointmentDto: AppointmentDto) => {
        const appointment: AppointmentDto = { ...appointmentDto, confirmed: false, createdAt: (new Date()).getTime() };
        setLoading(true);
        dispatch(addAppointment({ appointment })).unwrap().then(() => {
            reset();
            Notify.success('Appointment taken');
            setLoading(false);
        }).catch((err) => {
            Notify.warning('Error : Try again');
            setLoading(false);
        });
    };

    return (
        <section id="appointment" className="appointment section-padding">
            <div className="container">
                <div className="row">

                    <div className="col-12">
                        <h2 className="mb-4">Appointment</h2>
                    </div>

                    <div className="col-lg-6 col-12">
                        <form className="custom-form contact-form row" onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-lg-6 col-6">
                                <label htmlFor="fullName" className="form-label">Full Name</label>

                                <input type="text" id="fullName" className="form-control"
                                    placeholder="Your Name" {...register("fullName")} required />
                            </div>

                            <div className="col-lg-6 col-6">
                                <label htmlFor="phone" className="form-label">Phone Number</label>

                                <input type="telephone" id="phone" className="form-control"
                                    placeholder="123-456-7890" {...register("phone")} />
                            </div>

                            <div className="col-12">
                                <label htmlFor="email" className="form-label">Email</label>

                                <input type="email" id="email" pattern="[^ @]*@[^ @]*"
                                    className="form-control" placeholder="Your Email" {...register("email")} required={true} />


                                <label htmlFor="date" className="form-label">Date</label>

                                <input type="date" id="date"
                                    className="form-control" placeholder="Date" {...register("date")} required={true} />

                                <label htmlFor="time" className="form-label">Time</label>

                                <input type="time" id="time"
                                    className="form-control" placeholder="Time" {...register("time")} required={true} />



                                <label htmlFor="style" className="form-label">Style</label>

                                <select className="form-control" id="style"  {...register("style")} required={true}>
                                    <option value="">Style</option>
                                    <option value="SENEGALESE TWISTS">SENEGALESE TWISTS</option>
                                    <option value="BOX BRAID">BOX BRAID</option>
                                    <option value="TRAVEL BRAID">TRAVEL BRAID</option>
                                    <option value="SPRING TWIST">SPRING TWIST</option>
                                    <option value="Other">Other</option>
                                </select>


                                <input type="text" id="customStyle" hidden={style !== "Other"}
                                    className="form-control" placeholder="Your style name" {...register("customStyle")} required={style === "Other"} />

                                <div className="col-12">
                                    <p style={{ color: 'red', textAlign: 'center', fontWeight: '700' }}>This feature is currently under construction and will be available again soon.</p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-12 ms-auto">
                                <button disabled type="submit" className="form-control">{"Send"}</button>
                                {/* <button disabled={loading} type="submit" className="form-control">{loading ? "Loading" : "Send"}</button> */}
                            </div>
                        </form>
                    </div>

                    <div className="col-lg-4 col-12 mx-auto mt-lg-5 mt-4">

                        <h5>Weekdays</h5>

                        <div className="d-flex mb-lg-3">
                            <p>Monday to Friday</p>

                            <p className="ms-5">08:00 AM - 09:00 PM</p>
                        </div>

                        <h5>Weekends</h5>

                        <div className="d-flex mb-lg-3">
                            <p>Saturday</p>

                            <p className="ms-5">08:00 AM - 09:00 PM</p>
                        </div>

                        <div className="d-flex">
                            <p>Sunday</p>

                            <p className="ms-5">09:00 AM - 07:00 PM</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Appointment;